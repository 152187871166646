
import { ref, onMounted, onUnmounted, defineEmits, watch, Ref } from "vue";
import { defineComponent } from "vue";
import { boolean } from "yup";
import { useLoaderStore } from "@/store/LoaderStore";
import axios from "axios";
import { Modal } from "bootstrap";
import { useProductStore } from "@/store/ProductStore";
import router from "@/router";
import { useRoute } from "vue-router";
import { campHandleFileExtension } from '@/composables/DataValidation'
// import Api from "../../services/Api";
// import s3 from '../../services/S3Service'
import useAlert from "@/composables/Alert";
import { useFilterBaseV2Store } from "@/store/FilterBaseV2Store";

export default defineComponent({
  name: "uploadPlanilhas",
  emits: [
    "updateArrayNotValid",
    "openDragUpload"
  ],
  props: {
    openClose: Boolean,
    inforStore: Object
  },
  setup(props, {emit}) {

    // const { showTimeAlert } = useAlert();

    const arquivo: Ref<any> = ref(null);
    const buttonDisabled = ref(false);
    const fileInput: Ref<any> = ref(null);
    const marcas: Ref<any> = ref([]);
    const historicoArquivos: Ref<any> = ref([]);
    const dialogVisible = ref(false)
    const loaderStore = useLoaderStore()
    const productStore = useProductStore()
    const route = useRoute()
    const isXlsm = ref(true)
    const { showTimeAlert } = useAlert()

    // const {id: id_store} = route.params
    const filterBaseV2Store = useFilterBaseV2Store()
    const openInput = () => fileInput.value.click();

    const uploadFile = async (event: any) => {
      const file = event.target.files[0]
      arquivo.value = file
    };
  
    watch(() => props.openClose, () => {
      dialogVisible.value = true
    })

    emit('openDragUpload', dialogVisible.value)

    function resolveAndDownloadBlob(response: any) {
        let filename = 'invoices.xlsx';
        filename = decodeURI(filename);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        link.remove();
    }



    async function handleImportDoc() {
                  if(!(filterBaseV2Store.getStore)) {
                    showTimeAlert("É necessário ter uma loja setada", "error")
                    return
                  }

                  loaderStore.open() 
                  try {
                    const response = await axios.get(`/api/getExcelProductStore/${filterBaseV2Store.getStore.id}`, {
                      responseType: 'blob', // Important: Set the response type to 'blob' to handle binary data
                    })
                    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                    // Create a temporary link element
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = `Produtos da Loja ${filterBaseV2Store.getStore.fantasy_name}.xlsx`.replaceAll(" ", "_");
                    link.click();

                    // Clean up the temporary link object
                    link.remove();
                    dialogVisible.value = false
                    
                  } catch (error) {
                  } finally {
                    loaderStore.close()
                    clearFile()
                  }
    }

    function dropHandler(e) {

      let file;
      stopDefault(e)

      if (e.dataTransfer.items) {
        // Use DataTransferItemList interface to access the file(s)
        [...e.dataTransfer.items].forEach((item, i) => {
          // If dropped items aren't files, reject them
          if (item.kind === "file") {
            file = item.getAsFile();
          }
        });
      } else {
        // Use DataTransfer interface to access the file(s)
        [...e.dataTransfer.files].forEach((fileParam, i) => {
          file = fileParam;
        });
      }

      arquivo.value = file

    }

    function stopDefault(e) {
      // Prevent default behavior (Prevent file from being opened)
      e.stopPropagation();
      e.preventDefault();
    }


    function formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes';
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }


    async function cancelExport() {
      clearFile()
      dialogVisible.value = !dialogVisible.value
    }

    function clearFile() {
      arquivo.value = null
    }

    
    

    async function sendWorkSheet(e) {
              if(!(filterBaseV2Store.getStore)) {
                showTimeAlert("É necessário ter uma loja setada", "error")
                return
              }

              if(!campHandleFileExtension(arquivo.value, ['xlsx'])) {
                return isXlsm.value = false
              }
              loaderStore.open()
              const formData = new FormData()
              formData.append("file", arquivo.value);
                  try {
                    const response = await axios.post(`/api/verifyProductStore/${filterBaseV2Store.getStore.id}`, formData)
                    dialogVisible.value = false
                    productStore.getProductValidation(response.data.data, true)
                    router.push(`/lojas/produtos/adicionar`)
                    
                  } catch (error) {
                    loaderStore.close()
                  } finally {
                    clearFile()
                  }
    }

    function pegarListaDePlanilhas() {

      // s3.listObjects({ Bucket: 'varejo-storage', Delimiter: '/', Prefix: 'AtriaLub/planilhas/atualizacao_filtros/', }, (_, data) => {
      //   data.Contents.sort((a, b) => b.LastModified - a.LastModified)
      //   historicoArquivos.value = data.Contents.slice(0, 10)
      // })
    }

    function formatData(data: Date){

        const dia  = data.getDate().toString()

        const diaF = (dia.length == 1) ? '0'+dia : dia

        const mes  = (data.getMonth()+1).toString() //+1 pois no getMonth Janeiro começa com zero.

        const mesF = (mes.length == 1) ? '0'+mes : mes

        const anoF = data.getFullYear();

    return diaF+"/"+mesF+"/"+anoF;
}



    onMounted(() => {
      pegarListaDePlanilhas()
    })

    return {
    
      dropHandler,
      stopDefault,
      arquivo,
      clearFile,
      sendWorkSheet,
      cancelExport,
      historicoArquivos,
      formatBytes,
      openInput,
      uploadFile,
      fileInput,
      formatData,
      history,
      dialogVisible,
      buttonDisabled,
      handleImportDoc,
      isXlsm
    }
  },
});
